<template>

  <el-form :class="type == 'view' ? 'form-read-only' : ''"
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="row g-9 mb-7">

          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Name</label>
            <el-form-item prop="name" :rules="rules['field']">
              <el-input v-model="objData.name" placeholder="Name"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Email</label>
            <el-form-item prop="email" :rules="rules['field']">
              <el-input v-model="objData.email" placeholder="Email"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Password</label>
            <el-form-item prop="password" :rules="type == 'edit' ? [] : rules['field']">
              <el-input  type="password" v-model="objData.password" placeholder="Password"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Roles</label>
            <el-form-item prop="role_id" >
              <el-select class="select-display-block" clearable v-model="objData.role_id">
                <el-option v-for="item in roles"
                           :label="item.name"
                           :value="item.id"
                           :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <image-comp :element="objData.image" v-model="objData.image" col="col-md-3 fv-row" title="Image"></image-comp>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button v-if="type != 'view'"
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, inject, onBeforeMount, onMounted, ref, toRefs} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useStore} from "vuex";
import {useRoute} from 'vue-router'
import {Delete} from "@element-plus/icons-vue";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import {useRouter} from "vue-router/dist/vue-router";
import ImageComp from "@/components/admin/general/ImageComp.vue";

export default defineComponent({
  name: "admin-comp",
  components: {ImageComp},
  props: ['type'],
  setup(props) {
    const {type} = toRefs(props);
    const route = useRoute();
    const router = useRouter();
    const types = ref([]);
    const languages = ref([]);
    const store = useStore();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(null);
    const roles = ref([]);

    const isIndeterminate = ref(true)

    objData.value = {
      name: '',
      email: '',
      password: '',
      role_id : '',
      image : ''
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      switch (props.type) {
        case 'create' :
          try {
            response = await ApiAxiosService.get(APIs.ADMIN.create);
            languages.value = response.data.data.languages;
            roles.value     = response.data.data.roles;
          } catch (e) {
            handleError(e)
          }

          break;
        case 'edit' :
          try {
            response = await ApiAxiosService.get(APIs.ADMIN.edit(route.params.id));
            objData.value  = response.data.data.admin;
            languages.value = response.data.data.languages;
            roles.value     = response.data.data.roles;
          } catch (e) {
            handleError(e)
          }

          break;
        case 'view' :
          try {
            response = await ApiAxiosService.get(APIs.ADMIN.show(route.params.id));
            objData.value  = response.data.data.admin;
            languages.value = response.data.data.languages;
            roles.value     = response.data.data.roles;
          } catch (e) {
            handleError(e)
          }

          break;
      }
      loadingData.value = false;

    }

    const submitForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          switch (props.type) {
            case 'create' :
              storeResource();
              break;
            case 'edit' :
              updateResource();
              break;
          }

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const storeResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, objData.value[key]);
      });
      loading.value = true;
      ApiAxiosService.post(APIs.ADMIN.store, formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'admins-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const updateResource = () => {

      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, objData.value[key]);
      });
      formData.append('_method' , 'put');
      loading.value = true;
      ApiAxiosService.post(APIs.ADMIN.update(route.params.id), formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'admins-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }


    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      languages,
      initData,
      submitForm,
      storeResource,
      updateResource,
      type,
      types,
      isIndeterminate,
      roles
    };
  },

});
</script>

